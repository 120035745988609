var elem = '';
var $window = '';
var $this = '';
var $offset = '';
var $navbar = '';
var $stickySpacer = '';
var run = 0;

function widthCheck() {
  if ($window.width() < 992) {
    $this.css({width: 'calc(100% + 5px)'});
    $stickySpacer.css({marginTop: $this.height()});
  } else {
    $this.css({width: '33.33333333%'});
    $stickySpacer.css({marginTop: 0});
  }
}

function fixSticky() {
  if ($this.length) {
    $this.css({
      position: 'sticky',
      top: ($navbar + 20),
      left: ($this.offset().left),
      zIndex: 99
    });
  }
}

function releaseSticky() {
  $this.css({
    position: 'relative',
    top: 0,
    left: 0
  });
}

function releaseWidthCheck() {
  if ($window.width() < 992) {
    $this.css({width: '100%'});
  } else {
    $this.css({width: '33.33333333%'});
  }
}

function checkSticky() {
  $window = $(window);
  $stickySpacer = $('.sticky-spacer');
  $navbar = $('.navbar').height();

  if ($window.scrollTop() > ($offset.top - ($navbar + 20))) {

    if ($window.height() > ($navbar + $this.height() + 200)) {

      fixSticky();
      widthCheck();

    } else {

      releaseSticky();
      widthCheck();

    }

  } else {
    releaseSticky();
    releaseWidthCheck();
    $stickySpacer.css({marginTop: 0});
  }
}

function stickyFunction() {
  elem = document.getElementsByClassName('budget-sticky');

  if (elem.length > 0) {
    $this = $(elem[0]);
    $offset = $($this).offset();

    // console.log($this.length);
    checkSticky();
  }
}

window.onscroll = function() {
  elem = document.getElementsByClassName('budget-sticky');
  if (elem.length > 0 && run === 0) {
    run = 1;
    stickyFunction();
  } else {
    $this = $(elem[0]);
    checkSticky();
  }
};
window.resize = function() {
  if (elem.length > 0) {
    releaseSticky();
    releaseWidthCheck();
    checkSticky();
  }
};
